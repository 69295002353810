export enum MoleculeComponent {
    ActionTextArea = 'ActionTextArea',
    ActionTittle = 'ActionTittle',
    BeerCard = 'BeerCard',
    ContactItem = 'ContactItem',
    DrivingResponsibilityCard = 'DrivingResponsibilityCard',
    HeroDescription = 'HeroDescription',
    ImageTextArea = 'ImageTextArea',
    SiteMapLinks = 'SiteMapLinks',
    Social = 'Social',
    TextArea = 'TextArea',
    TextCard = 'TextCard',
    MyImage = 'MyImage',
    TimeLineCard = 'TimeLineCard',
    Touple = 'Touple',
    SliderComponent = 'SliderComponent',
    SliderItem = 'SliderItem'
}